.tours-table {
  position: relative;
  width: unquote("max(100%, var(--number-of-columns) * var(--column-width))");
}

.tours-table_header {
  position: sticky;
  top: 100px;
  @media screen and (max-width: 1000px) {
    top: 0;
  }

  background: var(--light-gray5);
  z-index: 10;

  & ~ .tours-table_header {
    top: initial;
    bottom: 0;
  }
}

.tours-table > h4 {
  background: var(--light-gray3);
  margin: 10px 0 0 0;
  padding: 5px 10px;

  &:first-of-type {
    margin-top: 0;
  }
}

.tours-table > div {
  display: flex;
}

.tours-table > div > * {
  flex-grow: 1;
  flex-basis: var(--column-width);
  border: 1px solid var(--light-gray3);
  line-height: 30px;
  text-align: center;
  margin: 0;
  min-width: var(--column-width);
}

.tours-table_time-slot-container {
}

.tours-table_time-slot {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  cursor: pointer;
  background: var(--light-gray5);
  &:nth-child(even) {
    background: var(--light-gray4);
  }

  & > span:first-child {
    color: var(--dark-gray5);
  }
}

.tours-table_tour-row .tours-table_time-slot {
  //margin-top: calc(var(--offset) / 15 * 7.5px);
  transition: margin-top 0.25s;
  margin-top: calc(var(--offset) * 30px);
}
