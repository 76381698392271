html {
  min-width: 800px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Variables from @blueprintjs/core/lib/scss/variables */

  --pt-grid-size: 10px;

  --light-gray1: #ced9e0;
  --light-gray2: #d8e1e8;
  --light-gray3: #e1e8ed;
  --light-gray4: #ebf1f5;
  --light-gray5: #f5f8fa;

  --gray1: #5c7080;
  --gray2: #738694;
  --gray3: #8a9ba8;
  --gray4: #a7b6c2;
  --gray5: #bfccd6;

  --dark-gray1: #182026;
  --dark-gray2: #202b33;
  --dark-gray3: #293742;
  --dark-gray4: #30404d;
  --dark-gray5: #394b59;

  --gold5: #ffc940;

  --red5: #ff7373;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
}
