.view-container {
  position: relative;

  @media screen and (max-width: 1000px) {
    position: static;
  }

  margin-top: 50px;
  & > .bp3-navbar ~ .bp3-navbar {
    margin-top: 50px;
  }
}

.view-content {
  margin-top: 100px;
}

.one-liner > .view-content {
  margin-top: 50px;
}
