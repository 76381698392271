.customer-editor {
  .bp3-numeric-input > .bp3-input-group {
    border-radius: 0;

    & > .bp3-icon {
      margin: 8px 6px;
      width: 12px;

      & > svg {
        margin: auto;
      }
    }

    & > .bp3-input {
      padding-left: 24px;
      text-align: center;
    }
  }

  .bp3-numeric-input:last-child > .bp3-input-group {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
